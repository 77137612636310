import React from 'react';
import './App.css'
import LiveVideMeet from './component/LiveVideMeet';


const App = () => {
  return (
    <div>
      <LiveVideMeet />
    </div>
  );
};

export default App;