import { useMeeting } from '@videosdk.live/react-sdk';
import React, { useState } from 'react';

const Navbar = (props) => {
    const { leave } = useMeeting();
    const [joined, setJoined] = useState(null);
    const { join } = useMeeting({
        onMeetingJoined: () => {
            setJoined("JOINED");
        },
        onMeetingLeft: () => {
            props.onMeetingLeave();
        },
    });

    const joinMeeting = () => {
        setJoined("JOINING");
        join();
    };

    const copyMeetingId = () => {
        navigator.clipboard.writeText(props.meetingId).then(() => {
            alert("Meeting ID copied to clipboard!");
        }).catch((err) => {
            console.error("Failed to copy meeting ID", err);
        });
    };

    return (
        <nav class="bg-white border-b border-gray-200 shadow-sm p-4 flex justify-center lg:justify-between items-center">
            <div class="flex items-center space-x-4 hidden lg:block">
                <span class="text-2xl font-bold text-gray-800">MeetingApp</span>
            </div>
            <div class="flex items-center space-x-3">
                <button
                onClick={copyMeetingId}
                    class="flex items-center bg-gray-100 hover:bg-[#136a3b] hover:text-white text-gray-700 px-2 py-1 lg:px-4 lg:py-2 rounded-full shadow-md transition duration-300 text-base">
                    <i class="fas fa-user-plus"></i>
                    <span class="sm:inline">Copy Meeting ID</span>
                </button>
                {
                    !joined &&   <button
                    onClick={joinMeeting}
                     class="flex items-center bg-green-700 hover:bg-[#136a3b] hover:text-white text-white px-2 py-1 lg:px-4 lg:py-2 rounded-full shadow-md space-x-2 transition duration-300 text-base">
                     <i class="fas fa-cog"></i>
                     <span class="sm:inline pr-2">Join</span>
                 </button>
                }
              
                <button
                   onClick={leave}
                    class="flex items-center bg-red-700 hover:bg-red-800 hover:text-white text-white px-2 py-1 lg:px-4 lg:py-2 rounded-full shadow-md space-x-2 transition duration-300 text-base">
                    <i class="fas fa-cog"></i>
                    <span class="sm:inline pr-2">Leave</span>
                </button>
            </div>
        </nav>
    );
};

export default Navbar;